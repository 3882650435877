import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/home/index.vue";
import PlayView from "../views/play/index.vue";
import ShouyeView from "../views/shouye/index.vue";

// xmxmy
// const routes = [
//   {
//     path: "/",
//     name: "shouye",
//     component: ShouyeView,
//   },
//   {
//     path: "/game",
//     name: "game",
//     component: HomeView,
//   },
//   {
//     path: "/play",
//     name: "play",
//     component: PlayView,
//   },
// ];

// art
const routes = [
  {
    path: "/",
    name: "shouye",
    component: HomeView,
  },
  {
    path: "/play",
    name: "play",
    component: PlayView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
